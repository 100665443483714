import React, { useState } from "react";
import Logo from "../assets/logo.svg";
import { Link } from "react-router-dom";
import "../styles/Navbar.css";
import ReorderIcon from "@mui/icons-material/Reorder";
//import { Button } from "@mui/material";

const linkStyle = {
  margin: "1rem",
  textDecoration: "none",
  color: "#006338",
};

function Navbar() {
  const [openLinks, setOpenLinks] = useState(false);

  const toggleNavbar = () => setOpenLinks(!openLinks);

  return (
    <div className="navbar">
      <div className="leftSide" id={openLinks ? "open" : "close"}>
        <a href="/">
          <img src={Logo} alt="company" />
        </a>
        <div className="hiddenLinks">
          <Link to="/" style={linkStyle}>
            Home
          </Link>
          {/*<Link to="/Prodotti">I Nostri Prodotti</Link>*/}
          <Link to="/Chi Siamo" style={linkStyle}>
            Chi Siamo
          </Link>
          <Link to="/Contattaci" style={linkStyle}>
            Contattaci
          </Link>
        </div>
      </div>
      <div className="rightSide">
        <Link to="/" style={linkStyle}>
          Home
        </Link>
        {/*<Link to="/Prodotti">I Nostri Prodotti</Link>*/}
        <Link to="/Chi Siamo" style={linkStyle}>
          Chi Siamo
        </Link>
        <Link to="/Contattaci" style={linkStyle}>
          Contattaci
        </Link>
        <button onClick={toggleNavbar}>
          <ReorderIcon />
        </button>
      </div>
    </div>
  );
}

export default Navbar;
