import React from "react";
import AboutUsImage from "../assets/mint3.png";
import "../styles/AboutUs.css";

function AboutUs() {
  return (
    <div
      className="AboutUs"
      style={{ backgroundImage: `url(${AboutUsImage})` }}
    >
      <div className="headerContainerAS">
        <h1>Chi siamo</h1>
        <p>
          Siamo Atlas Oasis, appassionati importatori di menta fresca,
          coriandolo, rape e altre verdure di alta qualità dal Marocco.
          Collaboriamo direttamente con i migliori produttori per garantire
          prodotti freschi e autentici.
        </p>
        <p>
          La nostra missione è offrire prodotti genuini, sostenibili e di alta
          qualità. Ci impegniamo per una catena di approvvigionamento
          trasparente, rispettando standard elevati di freschezza e sicurezza
          alimentare.
        </p>
        <p>
          Scegliendo Atlas Oasis, scegliete freschezza e qualità direttamente
          dal Marocco.
        </p>
      </div>
    </div>
  );
}

export default AboutUs;
