import React from "react";
import "../styles/Footer.css";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import BusinessIcon from "@mui/icons-material/Business";

function Footer() {
  return (
    <div className="footer">
      <div className="Inner">
        <div className="Icons">
          <p>
            <a
              href="https://maps.app.goo.gl/AYPsjavKXgHxf4r27"
              target="_blank"
              rel="noopener noreferrer"
            >
              <BusinessIcon />
              <p>Via Salvatore Barzilai, 149A, 00173 Roma RM, Italia</p>
            </a>
          </p>
        </div>
        <div className="Icons">
          <p>
            <a
              href="mailto:atlasoasis@info.it"
              target="_blank"
              rel="noopener noreferrer"
            >
              <EmailIcon />
              <p>info@atlasoasis.it</p>
            </a>
          </p>
        </div>
        <div className="Icons">
          <p>
            <a
              href="tel:+393383066254"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LocalPhoneIcon />
              <p>+393383066254</p>
            </a>
          </p>
        </div>
      </div>
      <p>
        <span>&copy; 2023 ATLAS OASIS S.R.L.S. P. IVA 17222611000</span>
      </p>
    </div>
  );
}

export default Footer;
