import React from "react";
import { Link } from "react-router-dom";
import BannerImage from "../assets/mint1.png";
import "../styles/Home.css";

function Home() {
  return (
    <div className="home" style={{ backgroundImage: `url(${BannerImage})` }}>
      <div className="headerContainer">
        <h1>Atlas Oasis</h1>
        <i>Menta e prodotti dal Marocco</i>
        <Link to="/Contattaci">
          <button> Contattaci!</button>
        </Link>
      </div>
    </div>
  );
}

export default Home;
