import React from "react";
import ContactImage from "../assets/mint2.jpg";
import "../styles/Contact.css";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import BusinessIcon from "@mui/icons-material/Business";
//import emailjs from "@emailjs/browser";

function Contact() {
  /*const form = useRef();

  function sendEmail(e) {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_yt1mhf4",
        "template_9jihuxw",
        form.current,
        "0mVR1_IiJokbNDVyD"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  }*/

  return (
    <div
      className="contact"
      style={{ backgroundImage: `url(${ContactImage})` }}
    >
      <div className="box">
        <div className="leftSide">
          <div className="Icons">
            <a
              href="https://maps.app.goo.gl/AYPsjavKXgHxf4r27"
              target="_blank"
              rel="noopener noreferrer"
            >
              <BusinessIcon />
              Via Salvatore Barzilai, 149A, 00173 Roma RM, Italia
            </a>
          </div>
          <div className="Icons">
            <a
              href="mailto:atlasoasis@info.it"
              target="_blank"
              rel="noopener noreferrer"
            >
              <EmailIcon />
              info@atlasoasis.it
            </a>
          </div>
          <div className="Icons">
            <a
              href="tel:+393383066254"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LocalPhoneIcon />
              +393383066254
            </a>
          </div>
        </div>
        <div className="rightSide">
          <h1> Contattaci</h1>
          <form
            name="contact"
            id="contact-form"
            method="post" /*onSubmit={sendEmail}*/
          >
            <input type="hidden" name="form-name" value="contact" />
            {/*<label htmlFor="name">Nome e Cognome</label>*/}
            <input name="name" placeholder="Nome e Cognome" type="text" />
            {/*<label htmlFor="company">Azienda</label>*/}
            <input name="company" placeholder="Azienda" type="text" />
            {/*<label htmlFor="email">e-mail</label>*/}
            <input name="email" placeholder="E-mail" type="email" />
            {/*<label htmlFor="message">Messaggio</label>*/}
            <textarea
              rows="6"
              placeholder="Messaggio"
              name="message"
              required
            ></textarea>
            <button type="submit">Invia</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Contact;
